:root {
    --main-primary: #3B2963;
    --main-primary-03: #3b29634d;
    --main-secondary: #c9b4de;
    --main-light: #F9EAFF;
    --main-dark: #111111;
    --main-white: #ffffff;
    --main-dark-03: #1111114d;
    --main-dark-05: #11111180;
    --main-dark-08: #111111b8;
    --main-dark-01: #1111111a;
    --main-coral: #ff2963;
    --main-green-025: #52c41a40;
    --main-box-shadow: 0px 18px 23px rgba(0, 0, 0, 0.05);
}