:root {
    --memos-menu-width: 400px;
}

.memos {
    height: var(--screen-height-without-header);
    width: var(--full);
    &__empty-state {
        height: var(--screen-height-without-header);
        width: 20%;
        margin: 0 auto;
    }
    &__menu {
        width: var(--memos-menu-width);
        margin-left: var(--gap-main);

        &__wrapper {
            height: calc(100vh - 110px);
            overflow-y: auto;
        }
        &__item {
            &:hover {
                .memos__menu__item__remove-button {
                    display: block;
                }
            }
            &__remove-button {
                padding: 5px;
                display: none;
            }
        }
    }
    .ant-menu-item-selected {
        .memos__menu__item__remove-button {
            display: block;
        }
    }
    &__add-memo {
        width: var(--full);
        margin: var(--gap-small) 0;
    }
    &__preview {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: var(--gap-large);
        height: var(--screen-height-without-header);
        overflow-y: auto;
        box-shadow: 3px 0px 7px -5px black;
        z-index: 1;
        &__input {
            color: #000000e0 !important;
        }
        &__textarea {
            color: #000000e0 !important;
        }
        &__edit {
            width: fit-content;
        }
    }
}