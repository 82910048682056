:root {
    --church-acts-container-max-width: 720px;
    --article-image-width: 270px;
}

.church-acts {
    width: var(--full);
    padding: var(--gap-main);
    &__container {
        max-width: var(--church-acts-container-max-width);
        margin: 0 auto;
    }

    &__cover {
        width: var(--article-image-width);
        float: left;
        margin-right: var(--gap-large);
        margin-bottom: var(--gap-large);
        margin-top: var(--gap-large);
    }
    &__description {
        line-height: var(--line-height-standard);
        margin-top: var(--gap-large);
        white-space: pre-wrap;
        text-align: justify;
    }
}