@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

.audios {
    height: var(--full);
    &__playlist {
        padding-top: var(--gap-main);
        width: var(--full);
    }
    &__current-song-details {
        padding-top: var(--gap-main);
        display: flex;
        flex-direction: column;
        width: var(--full);
        position: sticky;
        margin-left: var(--gap-main);
        top: 0;
        height: var(--screen-height-without-header);
        overflow-y: auto;
    }
    &__item-action {
        width: 30px;
        height: 30px;
        border: 1px solid var(--main-secondary);
        border-radius: var(--md-radius);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    &__empty-state {
        display: flex;
        flex: 1;
    }
    &__player {
        position: sticky;
        top: 0;
        z-index: 3;
        padding-left: var(--gap-main);
        padding-right: var(--gap-main);
        padding-bottom: var(--gap-main);
        background: var( --main-white);
        box-shadow: var(--main-box-shadow);
        border-radius: var(--md-radius);

    }
    &__lyrics {
        .about {
            font-size: 10px;
            margin-bottom: 0;
        }
    }
}
