@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

:root {
  --sidebar-scrollbar-width: 5px;
  --facet-title: var(--font-large-size);
  --header-lessonLanguage-height: 426px;
  --lesson-content-body-height: 90%;
  --line-height-standard: 1.5;
  --menu-item-height: 32px !important;
}
.learning {
  width: 100%;
  &__lang-wrapper {
    border-bottom: 1px solid var(--main-primary);
  }
  &__layout-content {
    box-shadow: 0px 0px 64px -38px var(--main-dark);
  }
  &__menu-wrapper {
    height: var(--screen-height-without-header);
    overflow-y: auto;
    background-color: var(--main-white);
    box-shadow: -10px 60px 102px -79px black;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: var(--main-dark-01);
    }
    &::-webkit-scrollbar{
      width: var(--sidebar-scrollbar-width);
    }

    &::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background-color: var(--main-dark-03);
    }
  }
  &__menu-item {
    height: 28px !important;
    padding: 0;
    span {
      color: var(--main-dark);
    }
  }
  &__content {
    width: var(--full) !important;
    height: var(--screen-height-without-header) !important;
    overflow-y: auto;
    background-color: var(--main-white);
  }
}
.lesson-content {
  width: 100%;
  height: var(--full);
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: var(--line-height-standard);
  &__video-wrapper {
    box-shadow: var(--main-box-shadow);
    min-width: 360px;
  }
  &__video {
    width: 100%;
  }
  &__expand-button {
    position: sticky;
    top: 0;
    left: 100%;
    background: var(--main-primary);
    color: var(--main-white);
  }
  &__body {
    margin-top: var(--gap-large);
    margin-bottom: var(--gap-large);
    padding: var(--gap-main);
    white-space: pre-wrap;
    flex: 1 1 auto;
    position: relative;
    transition: all 0.3s ease;

    &__with-video {
      margin-top: 0;
      margin-bottom: 0;
      padding: var(--gap-small) var(--gap-main);
      height: calc(100vh - var(--header-lessonLanguage-height));
      overflow: auto;
    }
  }
  &__expanded {
    height: var(--lesson-content-body-height);
    position: absolute;
    bottom: 0;
    background: white;
    transition: all 0.3s ease;
  }

  &__title {
    font-size: var(--font-large-size);
    color: var(--main-dark)
  }
}
.section-info {
  &__facet-title {
    font-size: var(--font-large-size);
    font-family: var(--font-bold);
    text-align: center;
    margin-bottom: var(--gap-large);
  }
  &__description-wrapper {
    margin-top: var(--gap-main);
  }
  &__description {
    width: fit-content;
    cursor: pointer;
    display: flex;
  }
}

.sublessons {
  margin-top: var(--gap-large);
  &__item {
    margin-bottom: var(--gap-large);
    &__verse {
      color: var(--main-primary);
      font-style: italic;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__title {
    text-align: center;
    font-family: var(--font-bold);
    font-size: var(--font-mid-size);
    cursor: pointer;
  }
  &__description-wrapper {
    margin-top: var(--gap-main);
  }
  &__description {
    font-family: var(--font-normal);
    font-size: var(--font-main-size);
  }
  &__list-wrapper {
    display: flex;
    margin-top: var(--gap-main);

    @include sm {
      flex-direction: column;
    }
  }
  &__list {
    &:first-child {
      margin-right: var(--gap-largest);
      @include sm {
        margin-right: 0;
      }
    }
  }
  &__list-item {
    &#{&}.title {
      font-weight: bold;
    }
  }
  &__subtitle {
    font-family: var(--font-bold);
    text-align: center;
    text-decoration: underline;
    margin-top: var(--gap-large);
  }
  &__p-title {
    text-align: center;
    margin-top: var(--gap-main);
  }
  &__p-sm-title {
    text-decoration: underline;
    font-family: var(--font-bold);
    margin-top: var(--gap-main);
  }
  &__p-bullets {
    font-family: var(--font-bold);
    &#{&}.sublessons__gap {
      margin-top: var(--gap-large);
    }
  }
  &__with-tab {
    padding-left: var(--gap-main);
  }
}

.conclusion {
  &__title {
    font-family: var(--font-bold);
    text-decoration: underline;
  }
}