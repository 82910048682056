@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

:root {
    --account-removal-help-image-width: 250px;
    --account-removal-section-width: 850px;
}

.account-removal {
    width: var(--full);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: var(--full);
    padding: var(--gap-large) 0;
    max-width: var(--account-removal-section-width);
    gap: var(--gap-large);
    background: var(--main-white);

    section {
        width: 50%;
        height: var(--full);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: var(--gap-small);
    }

    &__img {
        max-width: var(--account-removal-help-image-width);
        height: fit-content;
    }
}