:root {
  --video-player-height: 509px;
  --preaches-sider-width: 320px;
}

.preaches {
  &__sider {
    width: var(--preaches-sider-width);
    position: sticky;
    top: 0;
  }
}

.video-content {
  position: relative;
  flex-grow: 1;
    &__player {
      width: 100%;
      min-height: var(--video-player-height);
    }
    &__navigation {
      position: sticky;
      width: 100%;
      background: var(--main-secondary);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      bottom: 0;

      &__button {
        border-radius: var(--md-radius);
        padding: 0 var(--gap-main);
        color: var(--main-white);
        font-family: var(--font-bold);
        border: 2px solid var(--main-white);
        background: var(--main-secondary);
        &:hover {
          background: var(--main-white);
          color: var(--main-secondary);
        }
        &__inactive {
          cursor: not-allowed;
          &:hover {
            background: inherit;
            color: var(--main-white);
          }
        }
      }
    }
}
