@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

.man-direct {
    padding: var(--gap-main);
    width: var(--full);
    display: flex;
    justify-content: center;
    align-items: center;

    &__img {
        max-width: calc(var(--full) / 2);
        height: fit-content;
    }
}