@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

:root {
    --column-container-width: 1100px;
}

.home {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &__title {
        text-align: center;
    }
    &__column-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        
        @include md() {
            grid-template-columns: repeat(1, 1fr);
        }

        grid-column-gap: 25px;
        max-width: var(--column-container-width);
        margin: 0 auto;
    }
    &__background-image {
        position: fixed;
        max-width: 600px;
        min-width: 320px;
        width: 100%;
        top: 15%;
        opacity: 0.15;
    }
    &__container {
        z-index: 1;
    }
}

#home__container {
    z-index: 1;
}
