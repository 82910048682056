@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

.contact-us {
    padding-top: var(--gap-main);
    height: calc(100vh - var(--header-height));
}
.contactus {
    &__form {
        max-width: 500px;
        width: var(--full);
    }
}