.container {
  max-width: var(--container-base-width);
  padding-left: var(--container-base-padding);
  padding-right: var(--container-base-padding);
  width: 100%;
  margin: 0 auto;

  &__fluid {
    max-width: 100%;
    width: 100%;
  }
}