.verse-modal {
  border-radius: var(--gap-small);
  top: calc(var(--full) - 255px);
  &__title {
    font-family: var(--font-bold);
  }
  &__title-wrapper {
    margin-bottom: var(--gap-main);
  }
  &__description {
    
  }
}