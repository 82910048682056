@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

:root {
    --navigation-buttons-max-width: 410px;
    --book-selection-button: 300px;
    --book-selection-container: 500px;
    --chapter-grid-columns: repeat(8, 1fr);
    --viewer-full-size: 100%;
    --viewer-middle-size: 1440px;
    --viewer-small-size: 991px;
    --border-color-gray: #a8a8a8;
    --border-color-light-gray: #e8e8e8;
}

.bible {
    width: 100%;
    position: relative;
    overflow: hidden;

    &__bookmarks-drawer {
        .ant-drawer-header {
            .ant-drawer-header-title {

                button,
                .ant-drawer-title {
                    color: var(--main-coral);
                }
            }

            background: var(--main-white);
        }

        .ant-drawer-body {
            background: var(--main-primary);
        }
    }
}

.bookmarks {
    &__accordion {
        .ant-collapse-header {
            color: var(--main-white) !important;
            font-family: var(--font-bold);
            border-bottom: 1px solid var(--main-white);
        }
    }
}

.bible-navigation {
    height: 50px;
    display: flex;
    overflow-x: auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--gap-large);
    background: var(--main-white);
    box-shadow: 0px 10px 15px -3px #2d374845, 0px 4px 6px -2px rgba(45, 55, 72, 0.05);
    
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }

    &__bookmark-button {
        margin-right: 10px;
        padding: 0 10px;
        color: var(--main-coral) !important;

        &:hover {
            border-color: var(--main-coral) !important;
            color: var(--main-coral) !important;
        }
    }

    &__translation {
        display: flex;
        flex-direction: column;
    }

    &__translations {

        &__name,
        &__author {
            font-size: var(--font-main-size);
        }

        &__name {
            font-family: var(--font-bold);
        }

        &__item-container {
            display: flex;
        }

        &__label {
            margin-left: 0 !important;
            margin-bottom: var(--gap-main);
        }
    }

    &__buttons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: var(--navigation-buttons-max-width);
        width: var(--full);
    }

    &__book-selection-button {
        display: flex;
        align-items: center;
        min-width: var(--book-selection-button);
        justify-content: space-between;
    }

    &__book-selection {
        width: var(--book-selection-container);
    }

    &__books-select-element {
        width: var(--full);
        margin-bottom: var(--gap-main);
    }

    &__chapters-container {
        display: grid;
        grid-template-columns: var(--chapter-grid-columns);
        margin-bottom: var(--gap-large);
    }
}

.translation-viewer {
    width: 100%;
    height: calc(100vh - 110px);
    overflow: auto;

    &__header {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    &__table {
        border-collapse: separate;
        height: 1px;
        margin: 0 auto;
    }

    &__verse-with-note {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            background: var(--main-primary);
            width: 8px;
            height: 8px;
            border-radius: 100%;
        }
    }

    .notes-cell {
        position: sticky;
        background-color: white;
        right: 0;
        min-width: 300px;
        box-shadow: 0px 0px 8px 1px #aeaeae;
    }

    &__add-bookmark-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--main-primary-03);
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        font-size: var(--font-large-size);
        transition: all 0.1s ease;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }

        &__active {
            color: var(--main-coral);
        }
    }

    &__serach-btn {
        position: sticky;
        top: 0;
    }

    &__search-button {
        position: absolute;
        bottom: 100px;
        right: 10px;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    &__small-size {
        max-width: var(--viewer-small-size);
    }

    &__middle-size {
        max-width: var(--viewer-middle-size);
    }

    &__full-size {
        max-width: var(--viewer-full-size);
    }

    &__verse-title-container {
        padding-bottom: var(--gap-main);
        position: relative;

        &__bordered {
            padding: var(--gap-small);
            border-bottom: 1px solid var(--border-color-light-gray);
            transition: all 0.5s ease;

            &:not(:first-child) {
                border-left: 1px solid var(--border-color-gray);
            }

            &__active {
                background-color: var(--main-secondary);
                padding-top: var(--gap-largest);
            }
        }
    }

    &__verse-container {
        vertical-align: baseline;

        &__bordered {
            padding: var(--gap-small);
            border-bottom: 1px solid var(--border-color-light-gray);

            &:not(:first-child) {
                border-left: 1px solid var(--border-color-gray);
            }
        }
    }
}

.search {
    &__options {
        width: 40%;
    }

    &__result-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background: var(--main-light);
        }
    }

    // &__result-word-list {
    //     margin-top: var(--gap-largest);
    // }
    &__result-word-container {
        margin-top: var(--gap-large);
    }

    &__result-word {
        cursor: pointer;
        transition: all 0.1s ease;

        &.ant-typography {
            margin-bottom: var(--gap-small);
        }
    }

    &__result-content {
        padding-top: var(--gap-main);

        &__navigation {
            display: flex;
            align-items: center;
        }

        &__item {
            margin-top: var(--gap-small);
            cursor: pointer;

            &:hover {
                box-shadow: 0px 0px 7px -4px black;
            }
        }

        &__highlighted-word {
            background-color: var(--main-light);
            color: var(--main-light);
            font-family: var(--font-bold);
        }
    }
}

.verse-actions {
    display: flex;
    position: absolute;
    top: 100px;
    right: 16px;
    z-index: 1;
    border-radius: var(--md-radius);
}