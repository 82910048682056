.login {
    width: 100%;
    display: flex;
    justify-content: center;

    &__text {
        &#{&} {
            margin-bottom: var(--gap-large);
        }
    }
    &__container {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__form {
        width: 100%;

        &__item {
            // margin-bottom: var(--gap-main);
            &__input {
                min-height: var(--input-height);
            }
        }
    }
    &__submit {
        margin-bottom: var(--gap-main);
        min-height: var(--input-height);
        width: var(--full);
    }
}