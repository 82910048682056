@import './mixins.scss';
@import './variables/dimenisons.scss';
@import './variables/colors.scss';
@import './antTheme.scss';

@font-face {
  font-family: 'Dejavu';
  src: url(../../public/fonts/DejaVuSerif.ttf);
}
@font-face {
  font-family: 'Dejavu Italic';
  src: url(../../public/fonts/DejaVuSerif-Italic.ttf);
}
@font-face {
  font-family: 'Dejavu Bold';
  src: url(../../public/fonts/DejaVuSerif-Bold.ttf);
}
@font-face {
  font-family: 'Dejavu BoldItalic';
  src: url(../../public/fonts/DejaVuSerif-BoldItalic.ttf);
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html,
body {
  font-size: var(--font-main-size);
  font-family: var(--font-normal);
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  a {
    text-decoration: none;
  }
  p {
    margin-bottom: 0;
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style-type: none;
  }
}
body::-webkit-scrollbar, *::-webkit-scrollbar {
  width: 8px;
}

/* Track */
body::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
body::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
  background: var(--main-secondary); 
  border-radius: 8px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-thumb:hover {
  background: var(--main-primary); 
}
.app-container {
  display: flex;
  min-height: var(--screen-height-without-header);
}
