@import "../mixins.scss";

:root {
  --container-base-width: 1440px;
  --container-base-padding: 15px;
  --font-largest-size: 48px;
  --font-larger-size: 32px;
  --font-large-size: 24px;
  --font-mid-size: 18px;
  --font-main-size: 16px;
  --font-lab-size: 12px;
  --font-small-size: 8px;
  --font-bold: 'Dejavu Bold';
  --font-bold-italic: 'Dejavu BoldItalic';
  --font-normal: 'Dejavu';
  --font-italic: 'Dejavu Italic';
  --gap-largest: 32px;
  --gap-large: 24px;
  --gap-main: 16px;
  --gap-small: 8px;
  --gap-smallest: 4px;
  --md-radius: 8px;
  --sm-radius: 4px;
  --lg-radius: 16px;
  --rounded-radius: 100%;
  --header-height: 60px;
  --screen-height-without-header: calc(100vh - var(--header-height));
  --full: 100%;
  --line-height-standard: 1.5;
  --input-height: 40px;
}
@include lg {
  :root {
    --container-base-width: 1190px;
  }
}
@include md {
  :root {
    --container-base-width: 974px;
  }
}
@include sm {
  :root {
    --container-base-width: 720px;
  }
}
@include xs {
  :root {
    --container-base-width: 100%;
  }
}
// @include xs {
//   :root {
//   }
// }
