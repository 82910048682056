@import '../../../styles/mixins.scss';
@import '~antd/dist/reset.css';


:root {
    --calling-image-width: 560px;
}

.gods-calling {
    width: var(--full);
    display: flex;
    justify-content: center;
    align-items: center;
    &__img {
        max-width: var(--calling-image-width);
        height: fit-content;
    }
}