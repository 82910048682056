.bible-navigation-loader {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--gap-large);
  background: var(--main-white);
  box-shadow: 0px 10px 15px -3px rgba(45, 55, 72, 0.1), 0px 4px 6px -2px rgba(45, 55, 72, 0.05);
}

.bible-translation-viewer-loader {
  height: var(--full);
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    &:nth-child(2) {
      margin: 0 var(--gap-large);
    }
    width: 25%;
  }
}