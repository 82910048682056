:root {
    --main-sidebar-width: 400px;
}
.side-bar {
    width: var(--full);
    max-height: calc(100vh - var(--header-height));
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__opened {
        min-width: var(--main-sidebar-width);
        max-width: var(--main-sidebar-width);
        min-height: calc(100vh - var(--header-height));
    }
    &__footer {
        background: var(--main-dark-01);
        img {
            width: 35px;
            height: 35px;
        }
    }
}
.side-bar-container {
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
    box-shadow: -10px -55px 129px -79px black;
    z-index: 1;
    background: var(--main-white);
}
