@import '../styles/mixins.scss';
// :root {
//   --antd-wave-shadow-color: var(--main-secondary)
// }
// .ant-menu-item {
//   &#{&}-selected:not(.ant-menu-horizontal) {
//     background-color: var(--main-secondary);
//     span {
//       color: var(--main-white);
//     }
//     &::after {
//       content: none;
//     }
//   }
// }
// .ant-menu-item,
// .ant-menu-item-selected {
//   height: var(--gap-largest) !important;
//   margin: 0 !important;
//   padding: 0;
//   span {
//     font-size: var(--font-lab-size);
//     font-weight: var(--font-bold);
//   }
//   &:hover {
//     background-color: var(--main-secondary) !important;
//     span {
//       color: var(--main-white);
//     }
//   }
// }
// .ant-layout-sider-zero-width-trigger {
//   &-left {
//     background-color: var(--main-secondary);
//   }
// }
// .ant-layout-sider {
//   @include md() {
//     position: absolute;
//   }
// }

.ant-checkbox-group {
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
    margin-bottom: var(--gap-main);
  }
}