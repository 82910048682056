.memos {
    &__list {
        &__items {
            margin-top: var(--gap-main);
            max-height: 500px;
            overflow-y: auto;
        }
        &__item:not(:last-child) {
            margin-bottom: var(--gap-small);
        }
    }
}