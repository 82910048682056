@import '../../../../styles/mixins.scss';
@import '~antd/dist/reset.css';

.note {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  padding: var(--gap-small);
  position: relative;

  &__textarea {
    width: 100%;
    overflow-y: hidden;
    border-radius: var(--md-radius);
    padding: var(--gap-smallest);
    outline: 0;
    transform: all 0.1s ease;
    
    &__active {
      background-color: var(--main-green-025);
      border-color: var(--main-secondary);
      border-width: 2px;
    }
  }

  &__active {
    .note__action {
      display: flex;
    }
  }

  &__action {
    border-radius: var(--full);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-shadow: -5px 0px 6px -4px black;
    margin-right: var(--gap-small);
    background-color: var(--main-primary);
    color: white;

    &:hover {
      color: white;
    }
  }
  &__action {
    position: absolute;
    height: 30px;
    display: none;
    top: 0;
    right: 0;
    transform: translate(10px, -13px);
    transition: all 0.5s ease;
  }
  
  &:hover {
    .note__actions {
      display: flex;
    }
  }
}
