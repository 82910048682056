:root {
  --header-height: 160px;
}
.navbar {
  width: 100%;
  height: var(--header-height);
  background: var(--main-primary);
  padding-right: var(--gap-largest);
  padding-left: var(--gap-largest);

  color: white !important;

  &__logo {
    display: flex;
    align-items: center;
    img {
      max-width: 32px;
      max-height: 32px;
    }

    &__container {
      display: flex;
      align-items: center;
    }
  }
  &__mobile-menu {
    .navbar-item {
      margin-bottom: var(--gap-main);
      font-size: var(--font-main-size);
      font-family: var(--font-bold);
      color: var(--main-white);
      cursor: pointer;
      a {
        color: var(--main-white);
      }
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    position: relative;
    
    .navbar-item {
      margin-bottom: 0;
      font-size: var(--font-main-size);
      font-family: var(--font-bold);
      margin-left: var(--gap-main);
      color: var(--main-white);
      cursor: pointer;
      a {
        color: var(--main-white);
      }
    }
  }
  &__language {
    position: relative;
    &__list {
      width: 100%;
      position: absolute;
      background: var(--main-white);
      z-index: 100;
      left: 0;
      top: 100%;
      transform: translate(var(--gap-small), var(--gap-small));
      border: 1px solid var(--main-white);
    }
    &__item {
      padding: var(--gap-smallest);
      font-size: var(--font-main-size);
      font-family: var(--font-bold);
      color: var(--main-primary);
      text-align: center;
      cursor: pointer;
      &.active {
        background: var(--main-primary);
        color: var(--main-white);
      }
      &:hover {
        background: var(--main-primary);
        color: var(--main-white);
      }
    }
    &__btn {
      display: flex;
      align-items: center;
      color: var(--main-white);
      background: none;
      border: none;
      margin-left: var(--gap-main);
      font-family: var(--font-bold);
      cursor: pointer;

      img {
        margin-right: var(--gap-smallest);
      }
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__items__button {
    &__text {
      &#{&} {
        color: var(--main-primary);
      }
    }
  }
  &__items__text {
    &#{&} {
      color: var(--main-white);
      margin-bottom: 0;
      margin-right: var(--gap-main);
    }
  }
  &__mobile {
    width: 300px;
    padding: var(--gap-main);
    position: fixed;
    height: 100%;
    right: 0;
    top: 0;
    box-shadow: -10px -55px 129px -79px black;
    background: var(--main-primary);
    z-index: 1000;
    &__items {
      &__text {
        &#{&} {
          margin-top: var(--gap-main);
          color: var(--main-primary);
          text-align: center;
        }
      }
      &__button {
        margin-top: 12;
        width: var(--full);
        background: var(--main-primary);

        &__text {
          &#{&} {
            color: var(--main-white);
          }
        }
      }
    }
  }
}
